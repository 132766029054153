<script>
import DefaultMixin from "@/components/leiloes/mixin"
import RelatorioCell from '@/components/leiloes/components/relatorios/includes/RelatorioCell'
import {
  listRelatoriosDinamicos,
  download,
  relatorio,
  gerarDiarioLeilaoV2,
  catalogoTxt
} from "@/domain/relatorios/services"
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import {ErpSelect, ErpSField} from 'uloc-vue-plugin-erp'
import {donwloadFile} from "@/utils/downloadFile"
import createPrint from "@/components/relatorios/components/customPrint/createPrint"
import analiseBensSemArquivoWindow from "@/components/relatorios/components/bens/analiseBensSemArquivoWindow"
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item"
import {UPopover} from "uloc-vue"
import {findClassificacaoLeilao} from "@/domain/cadastro/services";
import {gerarEdital} from "@/domain/leiloes/services";
import {leilaoLog} from "@/domain/stats/service";

export default {
  name: 'Dashboard',
  inject: ['page', 'erplayout'],
  mixins: [DefaultMixin],
  components: {
    ECol,
    ERow,
    RelatorioCell,
    ErpSelect,
    ErpSField,
    MenuOptionsItem,
    UPopover
  },
  data() {
    return {
      leilao: this.page.leilao,
      loadingRD: false,
      gerandoRelatorio: false,
      relatorioDinamico: null,
      instancia: null,
      relatorios: [],
      idRelatorioParaGerar: null,
      relatoriosGerenciaisFiltros: {
        id: this.page.leilao.id
      },
      classificacao: null
    }
  },
  computed: {
    instanciaDatas () {
      const datas = [
        {label: 'Todas as datas', value: null}
      ]
      for (let i = 1; i <= this.leilao.instancia; i++) {
        datas.push({
          label: i + 'º leilão',
          value: i
        })
      }
      return datas
    }
  },
  mounted() {
    this.page.setActive('relatorios')
    this.relatoriosDinamicos()
    this.configuracaoByClassificacao()
    // document.querySelector('.u-erp-layout-container').classList.add('leilao-telao-seletor')
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('leilao-telao-seletor')
  },
  methods: {
    createPrint: createPrint,
    actionPrint (action) {
      this.gerar(action)
    },
    gerar(formato = 'html', orientation) { // Para relatórios gerenciais
      let filters = this.relatoriosGerenciaisFiltros
      this.$uloc.loading.show()
      relatorio(this.idRelatorioParaGerar, filters, formato)
          .then(response => {
            this.$uloc.loading.hide()
            formato === 'html' && this.createPrint(response.data, () => import('@/components/relatorios/components/customPrint/DefaultPrint'), 'leilao', 'Relatórios de Leilão', orientation)
          })
          .catch(error => {
            this.$uloc.loading.hide()
            if (formato === 'html') {
              this.alertApiError(error)
            } else{
              error.data.text().then(text => {
                this.alertApiError({data: JSON.parse(text)})
              })
            }
          })
    },
    gerarId (id, filtros = null, formato = 'html', orientation = 'landscape') {
      this.idRelatorioParaGerar = id
      if (filtros) {
        this.relatoriosGerenciaisFiltros = filtros
      } else {
        this.relatoriosGerenciaisFiltros = {id: this.leilao.id}
      }
      this.gerar(formato, orientation)
    },
    configuracaoByClassificacao () {
      this.leilao.classificacao && findClassificacaoLeilao(this.leilao.classificacao.id)
          .then((response) => {
            this.classificacao = response.data
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    relatoriosDinamicos() {
      this.loadingRD = true
      listRelatoriosDinamicos('leilao')
          .then(({data}) => {
            this.loadingRD = false
            this.relatorios = data.result.slice().map(r => {
              return {
                label: r.nome,
                value: r.id
              }
            })
            console.log(data)
          })
          .catch(error => {
            this.loadingRD = false
            this.alertApiError(error)
          })
    },
    gerarRelatorioDinamico() {
      console.log('Gerando ' + this.relatorioDinamico)
      this.gerandoRelatorio = true
      download(this.relatorioDinamico, {leilao: this.leilao.id})
          .then(response => {
            this.gerandoRelatorio = false
            donwloadFile(response)
          })
          .catch(error => {
            this.gerandoRelatorio = false
            this.alertApiError(error)
          })
    },
    gerarDiarioLeilaoV2 () {
      gerarDiarioLeilaoV2(this.leilao.id)
          .then(response => {
            donwloadFile(response)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    catalogoTxt () {
      catalogoTxt(this.leilao.id)
          .then(response => {
            donwloadFile(response)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    open (url) {
      if (this.$globalConfig && this.$globalConfig['sistema.ux.relatorio-nova-janela'] && this.$globalConfig['sistema.ux.relatorio-nova-janela'].value && Number(this.$globalConfig['sistema.ux.relatorio-nova-janela'].value) === 1) {
        let route = this.$router.resolve(url)
        window.open(route.href, '_blank')
        return
      }
      this.$router.push(url)
    },
    exportarLotes () {
      this.relatorioGeral('lotes')
    },
    relatorioGeral (id) {
      relatorio(id, {id: this.leilao.id})
          .then(response => {
            this.loading = false
            // donwloadFile(response)
          })
          .catch(error => {
            this.loading = false
            error.data.text().then(text => {
              this.alertApiError({data: JSON.parse(text)})
            })
            console.log(error)
          })
    },
    relatorioPagamentos () {
      this.gerarId('analisePagamentoLeilao', {
        id: this.leilao.id,
        tipo: 'todos'
      })
    },
    analiseBensSemArquivoWindow: analiseBensSemArquivoWindow,
    gerarEdital () {
      gerarEdital(this.leilao.id)
          .then((response) => {
            donwloadFile(response)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    baixarAuditoria () {
      leilaoLog(this.leilao.id, null, null, true)
          .then((response) => {
            donwloadFile(response)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    }
  },
  meta: {
    title: 'Relatórios do Leilão',
    name: 'Relatórios do Leilão'
  }
}
</script>

<template>
  <div class="leilao-relatorios-page page-leilao-container">
    <e-row class="m-t-n-xxl m-b">
      <e-col class="wrapper-sm e-input-modern size1">
        <erp-s-field
            view="tl"
            label="Relatórios em excel customizados:"
            icon-help="Relatórios montados dinamicamente. Podem ser adaptados ou criado novos"
        >
          <div class="flex no-wrap" style="max-width: 500px">
            <erp-select placeholder="Selecione"
                        size="2"
                        :options="relatorios"
                        v-model="relatorioDinamico"/>
            <e-btn :loading="gerandoRelatorio" @click="gerarRelatorioDinamico" class="m-l">
              <div class="text-nowrap flex items-center no-wrap">
                <i class="fa fa-duotone fa-file-csv font-16" /> <span class="m-l-sm">Gerar</span>
              </div>
            </e-btn>
          </div>
        </erp-s-field>
      </e-col>
      <e-col class="wrapper-sm e-input-modern size1" style="max-width: 300px">
        <erp-s-field
            view="tl"
            label="Emitir relatórios para:"
            icon-help="Os relatórios precisam estar preparados para atender os requisitos de leilão com mais de uma data. O funcionamento desta função depende da montagem dos relatórios, caso não funcione, fale com o suporte."
        >
          <div class="flex no-wrap" style="max-width: 500px">
            <erp-select placeholder="Selecione"
                        :disable="leilao.instancia < 2"
                        size="2"
                        :options="instanciaDatas"
                        v-model="instancia"/>
          </div>
        </erp-s-field>
      </e-col>
    </e-row>
    <div class="relatorios-leilao-container">
      <relatorio-cell @click.native="gerarEdital" title="Edital"
                      icon="file-pdf" icon-type="fa" icon-style="light"/>
      <relatorio-cell @click.native="open({name: 'leiloes.relatorios.certidao.publicacao'})"
                      title="Certidão de Publicação de Edital" icon="file-alt" icon-type="fa" icon-style="light"/>
    </div>
    <div class="relatorios-leilao-container">

      <relatorio-cell @click.native="open({name: 'leiloes.relatorios.resumo'})" title="Resumo do leilão"
                      icon="chart-pie" icon-type="fa" icon-style="light"/>
      <relatorio-cell title="Diário de Leilão"
                      icon="file-alt" icon-type="fa" icon-style="light">
        <u-popover class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
          <div class="menu_ menu-btn-leilao">
            <ul>
              <menu-options-item icon-type="fa" label="Por Lote" @click="open({name: 'leiloes.relatorios.diario.leilao'})" close />
              <menu-options-item icon-type="fa" label="Por Arrematante (PDF)" @click="gerarDiarioLeilaoV2" close />
            </ul>
            <slot />
          </div>
        </u-popover>
      </relatorio-cell>
      <relatorio-cell @click.native="open({name: 'leiloes.relatorios.arrematacao.leilao'})"
                      title="Auto Positivo de Arrematação" icon="file-check" icon-type="fa" icon-style="light"  class-style="lotesVendidos"/>
      <relatorio-cell @click.native="open({name: 'leiloes.relatorios.certidao.negativa'})"
                      :title="leilao.instancia > 1 ? 'Auto Negativo de Arrematação 1º Leilão' : 'Auto Negativo de Arrematação'" icon="file-exclamation" icon-type="fa" icon-style="light" class-style="lotesNaoVendidos"/>
      <relatorio-cell v-if="leilao.instancia > 1" @click.native="open({name: 'leiloes.relatorios.certidao.negativa2'})"
                      title="Auto Negativo de Arrematação 2º Leilão" icon="file-exclamation" icon-type="fa" icon-style="light" class-style="lotesNaoVendidos"/>
      <relatorio-cell @click.native="open({name: 'leiloes.relatorios.saida'})" title="Saídas/Vendas"
                      icon="chart-line" icon-type="fa" icon-style="light"/>
      <relatorio-cell @click.native="open({name: 'leiloes.relatorios.saida.arrematante'})"
                      title="Saídas/Vendas Unificado" icon="chart-line" icon-type="fa" icon-style="light"/>
      <relatorio-cell @click.native="open({name: 'leiloes.relatorios.lances'})" title="Lances" icon="chart-bar"
                      icon-type="fa" icon-style="light"/>
      <!--      <relatorio-cell @click.native="open({name: 'leiloes.relatorios.lances.comitente'})" title="Lances por Comitente" icon="user-chart" icon-type="fa" icon-style="light" />-->
      <relatorio-cell @click.native="open({name: 'leiloes.relatorios.lotes.retirados'})" title="Lotes Retirados"
                      icon="arrow-right" icon-type="fa" icon-style="light"/>
      <relatorio-cell @click.native="open({name: 'leiloes.relatorios.lotes.vendidos'})" title="Lotes Vendidos"
                      icon="gavel" icon-type="fa" icon-style="light" class-style="lotesVendidos"/>
      <relatorio-cell @click.native="open({name: 'leiloes.relatorios.lotes.condicional'})"
                      title="Lotes Condicional" icon="gavel" icon-type="fa" icon-style="light"
                      class-style="lotesCondicional"/>
      <relatorio-cell @click.native="open({name: 'leiloes.relatorios.lotes.nao.vendidos'})"
                      title="Lotes Não Vendidos" icon="gavel" icon-type="fa" icon-style="light"
                      class-style="lotesNaoVendidos"/>
      <relatorio-cell @click.native="open({name: 'leiloes.relatorios.numeros.visitas'})"
                      title="Números Visitas por Lotes" icon="eye" icon-type="fa" icon-style="light"/>
      <relatorio-cell @click.native="open({name: 'leiloes.relatorios.termo.alienacao'})"
                      title="Termo de Alienação" icon="file-alt" icon-type="fa" icon-style="light"/>
      <relatorio-cell @click.native="open({name: 'leiloes.relatorios.arrematacao.auto2'})"
                      title="Auto de Arrematação (Mod2)" icon="file-alt" icon-type="fa" icon-style="light"/>
      <relatorio-cell @click.native="exportarLotes"
                      title="Exportar Lotes" icon="file-spreadsheet" icon-type="fa" icon-style="light"/>
      <relatorio-cell @click.native="relatorioGeral('lances')"
                      title="Exportar Lances" icon="file-spreadsheet" icon-type="fa" icon-style="light"/>
      <relatorio-cell @click.native="relatorioPagamentos"
                      title="Pagamentos Efetuados" icon="money-check-alt" icon-type="fa" icon-style="light"/>
      <relatorio-cell @click.native="gerarId('analisePagamentoMaiorLeilao')"
                      title="Pagamentos Efetuados Maior que Devido" icon="funnel-dollar" icon-type="fa" icon-style="light"/>

      <relatorio-cell @click.native="gerarId('ata')" title="Veículos - Ata de Realização de Leilão" icon="car" icon-type="fa" icon-style="light"/>
      <relatorio-cell @click.native="gerarId('veiculosConfirmacaoDadosLeilao')" title="Veículos - Confirmação de Dados" icon="car" icon-type="fa" icon-style="light"/>
      <relatorio-cell @click.native="gerarId('relacaoPatioLeilao')" title="Veículos - Relação para Pátio" icon="car" icon-type="fa" icon-style="light"/>

      <relatorio-cell @click.native="analiseBensSemArquivoWindow" title="Analisar Ausência de Arquivos/Fotos dos Lotes" icon="car" icon-type="fa" icon-style="light"/>
      <relatorio-cell @click.native="catalogoTxt" title="Catálogo em TXT" icon="file" icon-type="fa" icon-style="light"/>
      <relatorio-cell @click.native="baixarAuditoria" title="Auditoria Operacional" icon="history" icon-type="fa" icon-style="light"/>

      <router-view/>

    </div>

    <e-row v-if="classificacao && classificacao.config && classificacao.config.templatesAdicionais">
      <e-col>
        <p class="font-16 m-t font-bold">Documentos e Relatórios adicionais para este leilão</p>
        <div class="relatorios-leilao-container m-t">
          <relatorio-cell
              v-for="template in classificacao.config.templatesAdicionais"
              :key="template.template.id"
              @click.native="gerarId('custom', {leilao: leilao.id, template: template.template.id}, 'html', 'normal')"
              :title="template.label"
              icon="file" icon-type="fa" icon-style="light"
          />
        </div>
      </e-col>
    </e-row>
  </div>
</template>

<style src="../relatorios/assets/global.styl" lang="stylus"/>
