<template>
  <div class="relatorio-cell">
    <div :class="classStyle">
      <div class="icon">
        <u-icon :name="icon" :type="iconType" :icon-style="iconStyle" class="fa-duotone" />
      </div>
      <div class="rtitle">{{title}}</div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
name: "RelatorioCell",
  props: ['title', 'icon', 'iconType', 'iconStyle', 'classStyle']
}
</script>
